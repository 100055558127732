/* jshint esversion:6 */

import moment from "moment";
// import message from "vue-m-message";
import accounting from "accounting";
import store from "@/core/services/store";
import { camelCase } from "lodash";

export const pageSize = 20;

export function mediaUrl(url = null) {
  if (isNullOrEmpty(url)) {
    return "/media/bg/no-photo.png";
  } else {
    return url;
  }
}

export function validEmail(email) {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

// export function validPhone(phone) {
//   const re = /^\+65(6|8|9)\d{7}$/;
//   return re.match(phone);
//   // return /\(?(?:\+62|62|08)(?:\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}/g.test(
//   //   phone
//   // );
// }

// export function validPhone(phone) {
//   return /\(?(?:\+62|62|08)(?:\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}/g.test(
//     phone
//   );
// }

export function capitalize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function initial(fullname) {
  return Array.prototype.map
    .call(fullname.split(" "), function (x, y) {
      return y < 2 ? x.substring(0, 1).toUpperCase() : "";
    })
    .join("");
}

export function truncate(str, len) {
  len = len || 100;
  return str.length > len ? str.substring(0, len) + "..." : str;
}

export function parseError(e) {
  let error = {};

  for (let key in e.messages) {
    let field = key.replace("_", " ");
    error["field"] = capitalize(field);
    error["messages"] = e.messages[key].join(", ");
  }

  return error;
}

export function toDate(date) {
  return moment(date).toDate();
}

export function getDay(date) {
  moment.locale("id-id");
  let format = "YYYY-MM-DD";
  return moment(date, format).format("dddd");
}

export function getDate(format) {
  moment.locale("id-id");
  format = format || "YYYY-MM-DD";
  return moment().format(format);
}

export function getDateTime() {
  moment.locale("id-id");
  let format = "YYYY-MM-DD HH:mm";
  return moment().format(format);
}

export function getYear() {
  moment.locale("id-id");
  let format = "YYYY";
  return moment().format(format);
}

export function startDate() {
  moment.locale("id-id");
  let format = "YYYY-MM-DD";
  return moment().format("D") == 1
    ? moment().add(-1, "M").startOf("month").format(format)
    : moment().startOf("month").format(format);
}

export function yearStart(format) {
  moment.locale("id-id");
  format = format || "YYYY-MM-DD";
  return moment().startOf("year").format(format);
}

export function yearEnd() {
  moment.locale("id-id");
  let format = "YYYY-MM-DD";
  return moment().endOf("year").format(format);
}

export function dateFormat(date, format) {
  if (date == null || isNullOrEmpty(date)) return "-";
  moment.locale("id-id");
  format = format || "DD/MM/YYYY";
  return moment(date).format(format);
}

export function dateRange(date1, date2, format) {
  format = format || "DD/MM/YYYY";

  if (date1 == null || date2 == null) return "-";
  else return `${dateFormat(date1, format)} - ${dateFormat(date2, format)}`;
}

export function ageCalculator(date) {
  let startDate = new Date(new Date(date).toISOString().substr(0, 10));
  const endingDate = new Date().toISOString().substr(0, 10); // YYYY-MM-DD
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();

  // Leap years
  const february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  let dayDiff = endDate.getDate() - startDate.getDate();

  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  return `${yearDiff} Tahun, ${monthDiff} Bulan, ${dayDiff} Hari`;
}

export function indonesianDatetime(date) {
  moment.locale("id-id");
  return moment(date).add(7, "hours");
}

export function dateTimeFormat(date, format) {
  if (date == null || isNullOrEmpty(date)) return "-";
  moment.locale("id-id");
  format = format || "DD/MM/YYYY HH:mm:ss";
  return moment(date).format(format);
}

export function numberFormat(number, precision = 0, delimiter = ".") {
  let result = null;
  if (Math.floor(number) === number) {
    result = accounting.formatNumber(
      number,
      0,
      delimiter == "." ? "." : ",",
      delimiter == "." ? "," : "."
    );
  } else {
    result = accounting.formatNumber(
      number,
      precision,
      delimiter == "." ? "." : ",",
      delimiter == "." ? "," : "."
    );
  }

  return result;
}

export function numberUnFormat(number) {
  return accounting.unformat(number);
}

export function isLastDateOfMonth(date) {
  moment.locale("id-id");
  let format = "YYYY-MM-DD";
  return (
    moment(date).format(format) == moment(date).endOf("month").format(format)
  );
}

export function dateAdd(date, length, period, useLocale = true) {
  if (useLocale) moment.locale("id-id");

  return moment(date).add(length, period).toDate();
}

export function years() {
  let result = [];
  for (let item = 1900; item <= 2099; item++) {
    result.push({
      id: item,
      label: item,
    });
  }

  return result;
}

export function tableHeight() {
  return document.documentElement.clientHeight - 295;
}

export function convertAmpersand(text) {
  return text.replace(/&amp;/g, "&");
}

export function hoursAgo(date) {
  if (date != null) {
    moment.locale("id-id");
    return moment(date).fromNow();
    /* let now = moment(new Date());
    let end = moment(date);
    let duration = moment.duration(now.diff(end));
    let days = duration.asDays();

    if (days >= 1) {
      return moment(date).format("DD MMM YYYY HH:mm");
    } else {
      return moment(date).format("HH:mm");
    } */
  }

  return null;
}

export function duration(date) {
  if (date != null) {
    moment.locale("id-id");
    let now = moment(new Date());
    let end = moment(date);
    let duration = moment.duration(now.diff(end));
    let hour = moment.utc(duration.asMilliseconds()).format("H");
    let minute = moment.utc(duration.asMilliseconds()).format("mm");
    return `${hour} jam, ${minute} menit`;
    /* let days = duration.asDays();

    if (days >= 1) {
      return moment(date).format("DD MMM YYYY HH:mm");
    } else {
      return moment(date).format("HH:mm");
    } */
  }

  return null;
}

export function stripHTML(str) {
  let regex = /(<([^>]+)>)/gi;
  return str.replace(regex, "");
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function dateFromNumber(excelDate) {
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
}

export function getExt(filename) {
  return (
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
    filename
  );
}

export function arraySelected(arr, value) {
  if (arr.includes(value)) {
    // console.log(true);
    // return arr.filter(item => item !== value);
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  } else {
    // console.log(false);
    return arr.push(value);
  }
}

/* export function phoneValidator(str) {
  let regex = RegExp(r'^(8|9)\d{7}$');
  return regex.hasMatch(str);
} */

export function groupBy(arr, key) {
  const initialValue = {};
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
}

export function camelizeKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export function isNullOrEmpty(str = null) {
  if (str === null || str === "") return true;
  else return false;
}

export function normalizer(node) {
  return {
    id: node.value,
    label: node.text,
  };
}

export function setPersentase(params, precision = 0) {
  if (params == 0) return "-";
  return `${numberFormat(params, precision)}%`;
}

export function setRupiah(params, precision = 0, prefix = "Rp") {
  return `${prefix}${numberFormat(params, precision)}`;
}

export function setMinRupiah(params, precision = 0, prefix = "Rp") {
  if (params == null || params == 0)
    return `${prefix}${numberFormat(params, precision)}`;
  else
    return `(${prefix}${numberFormat(
      params < 0 ? params * -1 : params,
      precision
    )})`;
}

export async function getStore() {
  let result = [];

  await store
    .dispatch("apis/get", {
      url: "/store",
      params: {
        orderBy: "storeName",
      },
    })
    .then((response) => {
      result = response.data;
    });

  return result;
}

export async function getPaymentMethod(storeId) {
  let result = [];

  await store
    .dispatch("apis/get", {
      url: `/store/${storeId}/payment-method`,
      params: {
        orderBy: "label",
      },
    })
    .then((response) => {
      result = response.data;
    });

  return result;
}

export async function getCashier(storeId) {
  let result = [];

  await store
    .dispatch("apis/get", {
      url: `/userAccount`,
      params: {
        storeId: [storeId],
        businessOwner: false,
        orderBy: "fullName",
      },
    })
    .then((response) => {
      result = response.data;
    });

  return result;
}

export const suggestions = [2000, 5000, 10000, 20000, 50000, 100000, 0];
